/*

@include grid-row(16);

@include grid-column(4 / 15% / 1 of 5);

@include grid-layout($n, $selector); (block grid)

@include breakpoint(small/medium/large up/only){}

*/

$pmrs_red: #EE2A38;

a:focus, button:focus{

  outline:none;

}