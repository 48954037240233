/*

@include grid-row(16);

@include grid-column(4 / 15% / 1 of 5);

@include grid-layout($n, $selector); (block grid)

@include breakpoint(small/medium/large up/only){}

*/

.animated-banner{

  background:url('/assets/img/home_street.jpg') no-repeat center center;
  background-size:cover;
  background-position: center center;
  height:300px;
  margin-bottom:45px;
  position: relative;


  @include breakpoint(large up){

    margin-bottom:90px;
    height: 750px;

  }

  &__headings{

    position: relative;
    top: 50%;
    transform: translateY(-50%);

    h1{

      text-align:center;
      font-size: rem-calc(32);
      line-height: rem-calc(35);
      color: #FFFFFF;
      text-shadow: 0px 2px 8px rgba(0,0,0,0.50);
      display:none;

      @include breakpoint(large up){

        font-size: rem-calc(64);
        line-height: rem-calc(70);


      }

    }

    span{

      min-height:35px;
      display:block;

    }

    h3{
      text-align:center;
      font-size: rem-calc(16);
      line-height: rem-calc(18);
      color: $pmrs_red;
      text-shadow: 0px 2px 2px rgba(0,0,0,1);
      display:none;
      text-align:center;
      margin-top:0;
      margin-bottom:0;

      @include breakpoint(large up){

        font-size: rem-calc(32);
        line-height: rem-calc(35);

      }

    }

  }

  &__heatmap{

    position: absolute;
    left:5%;
    top:10%;
    height:250px;
    display:none;
    opacity:0.5;

    @include breakpoint(large up){

      left:25%;
      top:10%;
      height:500px;

    }

  }

  &__report{

    position: absolute;
    left:20%;
    top:10%;
    height:250px;
    display:none;
    opacity:0.5;

    @include breakpoint(large up){

      left:40%;
      top:10%;
      height:600px;

    }

  }

  &__clicker{

    position: absolute;
    right:0;
    bottom:0;
    height:100px;
    opacity:100;

    @include breakpoint(medium up){

      height:150px;

    }


    @include breakpoint(large up){

      height:350px;

    }

    @include breakpoint(xxlarge up){

      height:450px;

    }

  }

}


.standard-banner{

  margin-bottom:45px;
  height: 250px;
  background-size:cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  display: table;
  width:100%;

  @include breakpoint(large up){

    margin-bottom:90px;
    height: 250px;

  }

  &__headings{

    display: table-cell;
    vertical-align: middle;

    h1{

      text-align:center;
      font-size: rem-calc(32);
      line-height: rem-calc(35);
      color: #FFFFFF;
      text-shadow: 0px 2px 8px rgba(0,0,0,0.50);

      @include breakpoint(large up){

        font-size: rem-calc(64);
        line-height: rem-calc(70);


      }

    }

  }

}
