/*

@include grid-row(16);

@include grid-column(4 / 15% / 1 of 5);

@include grid-layout($n, $selector); (block grid)

@include breakpoint(small/medium/large up/only){}

*/


nav{

  background:$pmrs_red;
  text-align:center;
  height: 60px;

  ul.dropdown.menu{

    text-align:left;
    display: inline-block;
    margin:0 auto;
    height: 60px;

    >li{

      height:60px;

    }

    li{

      a{

        color:white;
        font-size:rem-calc(20);

        @include breakpoint(medium only){
            font-size:rem-calc(16);            
        }

        font-weight:700;

      }

    }

    .menu{

      display:none;

    }

    .submenu{

      background:$pmrs_red;
      border:none;

      >li{

        border-bottom: white 1px solid;

        &:last-child{

          border-bottom: none;

        }

      }

    }

  }

}

.slicknav_menu {
  display:none;
}

@include breakpoint(small only){

  .js nav #menu {
    display:none;
  }

  .js nav .slicknav_menu {
    display:block;
  }

}

/* Down arrow styling for main menu */

.dropdown.menu .is-dropdown-submenu-parent.is-down-arrow>a::after{

  border-color: white transparent transparent;
  top: 1.125rem;

  @include breakpoint(medium only){
      top: 0.9rem;            
  }  
  

}