/*

@include grid-row(16);

@include grid-column(4 / 15% / 1 of 5);

@include grid-layout($n, $selector); (block grid)

@include breakpoint(small/medium/large up/only){}

*/


footer{

  position:relative;
  margin-top:100px;

  hr{


    margin-bottom:40px;
    border-bottom: 2px solid #D8D8D8;
    display: inline-block;
    width: 100%;

  }

 .footer-contents-container{

    padding-bottom:140px;

  }

  .footer-image{

    background:url('/assets/img/home_street.jpg') no-repeat center center;
    background-size:cover;
    height:130px;
    width:100%;
    position:absolute;
    right:0;
    bottom:0;

  }

  .footer-clicker-hand{

    position:absolute;
    right:0;
    bottom:100px;
    height:150px;

    @include breakpoint(large up){

      height:250px;

    }

    img{

      height:150px;

      @include breakpoint(large up){

        height:350px;

      }

    }

  }

  .footer-nav{

    @include breakpoint(large up){

      border-right: 2px solid #D8D8D8;

    }

   ul{

     list-style-type: none;
     margin-left:0;
     padding-left:0;
     @include breakpoint(large up){

       margin-left:0;
       padding-left:0;

     }

     li{

       a{

         padding-top:0.1rem;
         padding-bottom:0.1rem;
         color:#000;
         font-size: rem-calc(14);
         line-height: rem-calc(16);

         &:hover{

           color:$pmrs_red;

         }

       }

     }

   }

  }

  .footer-company-info{

    font-size: rem-calc(14);
    line-height: rem-calc(20);
    margin-bottom:1rem;

    @include breakpoint(large up){

      margin-bottom:0;
      padding-left: 30px;
      border-right: 2px solid #D8D8D8;

    }

  }

  .footer-contact-info{

    font-size: rem-calc(14);
    line-height: rem-calc(20);

    @include breakpoint(large up){

      padding-left: 30px;

    }

    a{

      color:#000;

      &:hover{

        color:$pmrs_red;

      }

    }

  }

}


.siteby {
  width: 100%;
  clear: both;
  text-align: center;
  padding-top: 20px;
  font-size: rem-calc(12);
}