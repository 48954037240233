/*

@include grid-row(16);

@include grid-column(4 / 15% / 1 of 5);

@include grid-layout($n, $selector); (block grid)

@include breakpoint(small/medium/large up/only){}

*/


.services-block{
  display: block;
  min-height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin-bottom: 40px;

  @include breakpoint(large up){

    margin-bottom: 0;

  }

  span {
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    z-index: 2;
    color:white;
    font-weight:700;
    line-height:110%;
    font-size:rem-calc(36);

    @include breakpoint(medium only ){
      font-size:rem-calc(22);
    }

  }

  &:after {
    background: #ee2a38;
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 0.5s ease-in-out;
    z-index: 1;
  }

  &:hover:after {
    opacity: 0.8;
  }
}