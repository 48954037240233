/*

@include grid-row(16);

@include grid-column(4 / 15% / 1 of 5);

@include grid-layout($n, $selector); (block grid)

@include breakpoint(small/medium/large up/only){}

*/

.cta-holder{

  margin-top:30px;

  @include breakpoint(large up){

    margin-top:60px;

  }

  p{

    margin-bottom:2rem;
    text-align: center;

  }


  .callout{

    padding:3rem;
    background:#f6f6f7;

  }

  a{

    margin: 0 auto;
    display:block;

    @include breakpoint(large up){

      width:300px;

    }


  }

}

/* -------- pmrs thumbnails -------- */

.pmrs-thumbnail {
  border: none;
  display: block;
  margin: 20px auto;
}