/*

@include grid-row(16);

@include grid-column(4 / 15% / 1 of 5);

@include grid-layout($n, $selector); (block grid)

@include breakpoint(small/medium/large up/only){}

*/

.row-with-divider{

  hr{

    margin-top:40px;
    margin-bottom:40px;
    border-bottom: 2px solid #D8D8D8;
    display: inline-block;
    width: 100%;

  }

  ul{

    list-style-type: none;
    margin-left:0;
    margin-bottom:0;
    margin-right: 2rem;

    &:nth-of-type(1){

      float:left;

    }

    &:nth-of-type(2){

      float:left;

      @include breakpoint(large up){

        float:right;

      }

    }

    li{

      list-style-type: none;
      background-image: url(/assets/img/red-chevron-right.png);
      background-repeat: no-repeat;
      background-position: 1px 7px;
      background-size: 7px 14px;
      padding-left: 20px;
      margin-bottom: 10px;

    }

  }

}