
.contact-form-fields {

    dt {

        span {
            color: #c00;
            font-weight: bold;
        }

    }

    dd {
        margin-bottom: 10px;

        strong {
            color: #c00;
            margin-bottom: 10px;
        }

    }

    input {
        margin: 0;
    }


}