/*

@include grid-row(16);

@include grid-column(4 / 15% / 1 of 5);

@include grid-layout($n, $selector); (block grid)

@include breakpoint(small/medium/large up/only){}

*/


header{


  .contact-details{

    position: relative;
    top: 50%;
    transform: translateY(-50%);

    p{

      font-size: rem-calc(15);
      margin-bottom:5px;
      color:#212121;

      @include breakpoint(large up){

        font-size: rem-calc(24);
        margin-bottom:12px;

      }

    }

  }

  .header--logo{

    position: relative;
    top: 50%;
    transform: translateY(-50%);

  }

  .header-top{
    
    padding-top:20px;
    padding-bottom:10px;
    height: 150px;

    @include breakpoint(medium up){

      height: 175px;

    }

    @include breakpoint(large up){

      height: 250px;

    }

  }

}
