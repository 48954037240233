/*

@include grid-row(16);

@include grid-column(4 / 15% / 1 of 5);

@include grid-layout($n, $selector); (block grid)

@include breakpoint(small/medium/large up/only){}

*/

.centre-search-content{

  margin-top:rem-calc(50);

  @include breakpoint(large up){

    margin-top:rem-calc(100);

  }

}

.centre-search {
	.needs-top-padding {
		line-height: 2.875rem;
	}
}
