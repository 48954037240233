/*

@include grid-row(16);

@include grid-column(4 / 15% / 1 of 5);

@include grid-layout($n, $selector); (block grid)

@include breakpoint(small/medium/large up/only){}

*/




blockquote, dd, div, dl, dt, form, h1, h2, h3, h4, h5, h6, li, ol, p, pre, td, th, ul,label,input{

  
  font-size: rem-calc(16);
  line-height: rem-calc(22);
  

  @include breakpoint(medium up){

    font-size: rem-calc(18);
    line-height: rem-calc(26);

  }

  @include breakpoint(large up){

    font-size: rem-calc(22);
    line-height: rem-calc(30);

  }

}

h1, h2, h3, h4, h5, h6{

  color:$pmrs_red;
  font-weight:700;
  margin-bottom:1rem;
  font-size: rem-calc(30);
  line-height: rem-calc(34);

  @include breakpoint(medium up){

    font-size: rem-calc(36);
    line-height: rem-calc(46);

  }

  @include breakpoint(large up){

    font-size: rem-calc(36);
    line-height: rem-calc(46);

  }
  
}

.small {
  font-size: 0.9rem;
  line-height: 1.2rem;
}
