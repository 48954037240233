//colors
$color_0: rgba(255, 255, 255, 0.75);
$color_btn: darken(#EE2A38,10%);
$color_text: #fff;
$color_shadow: #000;
$color_bg: #EE2A38;
$color_hover_bg: #EE2A38;
$color_hover_text: #fff;

.slicknav_menu {
	font-size: 16px;
	box-sizing: border-box;
	background: $color_bg;
	padding: 5px;
    position:absolute;
    z-index:10;
    width:100%;
    text-align:left;
	* {
		box-sizing: border-box;
	}
	.slicknav_menutxt {
		color: $color_text;
		font-weight: bold;

	}
	.slicknav_icon-bar {
		background-color: $color_text;
	}
}
.slicknav_btn {
	margin: 5px 5px 6px;
	text-decoration: none;

	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	background-color: $color_btn;
}
.slicknav_nav {
	color: $color_text;
	margin: 0;
	padding: 0;
	font-size: 0.875em;
	list-style: none;
	overflow: hidden;
	ul {
		list-style: none;
		overflow: hidden;
		padding: 0;
		margin: 0 0 0 20px;
	}
	.slicknav_row {
		padding: 5px 10px;
		margin: 2px 5px;
		&:hover {
			-webkit-border-radius: 6px;
			-moz-border-radius: 6px;
			border-radius: 6px;
			background: $color_hover_bg;
			color: $color_text;
		}
	}
	a {
		padding: 5px 10px;
		margin: 2px 5px;
		text-decoration: none;
		color: $color_text;
		&:hover {
			-webkit-border-radius: 6px;
			-moz-border-radius: 6px;
			border-radius: 6px;
			background: $color_hover_bg;
			color: $color_hover_text;
		}
	}
	.slicknav_txtnode {
		margin-left: 15px;
	}
	.slicknav_item a {
		padding: 0;
		margin: 0;
	}
	.slicknav_parent-link a {
		padding: 0;
		margin: 0;
	}
}
.slicknav_brand {
	color: $color_text;
	font-size: 18px;
	line-height: 30px;
	padding: 7px 12px;
	height: 44px;
}